<template>
	<div class="row patient_chart add_local_files">
		<div class="col-lg-5 edit_record_page d-flex flex-column justify-content-between">
			<div>
				<div class="a_header" v-if="this.isShowInboxFrm">
					<div class="text-end mb-3">
						<span class="edit_delet_link">
							<span class="popup_link" @click="splitpdf()" v-if="!form.deleted_datetime">Split Pages</span>
							<span class="popup_link" @click="deleteRecord(form.id)" v-if="!form.deleted_datetime">Delete </span>
						</span>
					</div>
				</div>
				<div class="containt_detail" v-if="this.isShowInboxFrm">
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Uploaded
							Date</label>
						<div class="col-sm-7">
							<div class="pi-info">{{ this.form.created_datetime_format }}</div>
						</div>
					</div>
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Document 
							<span class="white-space-nowrap">Description <span class="asterisk-red">*</span></span>
						</label>
						<div class="col-sm-7">
							<input type="text" class="form-control" v-model="form.description" :readonly="this.form.deleted_datetime">
							<ValidationErrorMessageList :errors="v$.form.description.$errors" />
						</div>
					</div>

					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">
							<span class="white-space-nowrap">Demographic <span class="asterisk-red">*</span>
							</span>
						</label>
						<div class="col-sm-7">
							<div class="position-relative">
								<div class="search">
									<img class="serch_icon" src="/images/search-icon.svg" alt="">
									<input type="text" @keyup="patientSearch" class="form-control w-100" :readonly="this.form.deleted_datetime"
										placeholder="Search Patient" v-model="patient_search" ref="globalSearch">
									</div>
								<ValidationErrorMessageList :errors="v$.form.patient_id.$errors" />
								<div class="search_drop_box split-demographic-search" v-if="patientList.length > 0 && !this.form.deleted_datetime">
									<slot v-for="(item, index) in patientList" :key="index">
										<div class="con_drop_line"
											@click="selectPatient(item.patient_id, item.last_name + ', ' + item.first_name)">
											<div class="row px-2 py-3 align-items-center">
												<div class="col-md-12">
													<div class="man_haeding">
														{{ item.last_name }}, {{ item.first_name }}
														<span>({{ item.gender }})</span>
													</div>
													<ul>
														<li>
															DOB: {{ item.dob }}
														</li>
														<li>
															HIN: {{ item.health_insurance_no }}
														</li>
														<li>
															Tel: {{ item.cell_phone }}
														</li>
													</ul>
												</div>
											</div>
										</div>
									</slot>
									<div class="create_grpc border-top"><router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link></div>
								</div>
							</div>
						</div>
					</div>
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Document
							<span class="white-space-nowrap"> Type <span class="asterisk-red">*</span></span>
						</label>
						<div class="col-sm-7">
							<div class="document-type-option">
								<CustomDropDown :options="docTypeList" :initialValue="form.report_class_id"
									@item-selected="handleItemSelected" :isDisabled="this.form.deleted_datetime"
									fieldName="report_class_id"></CustomDropDown>
								<ValidationErrorMessageList :errors="v$.form.report_class_id.$errors" />
							</div>
						</div>
					</div>
					<div class="row pd-bottom" v-if="this.isOtherType">
						<label for="text" class="col-sm-5 col-form-label right-bor">Document Sub-Type</label>
						<div class="col-sm-7"><input type="text" class="form-control" v-model="form.report_sub_class_name"
							:readonly="this.form.deleted_datetime" :initialValue="form.report_sub_class_name"></div>
					</div>
					<div class="row pd-bottom" v-if="!this.isOtherType">
						<label for="text" class="col-sm-5 col-form-label right-bor">Link with Tracing 
							<span class="white-space-nowrap">Requests
								<span class="asterisk-red">*</span>
							</span>
						</label>
						<div class="col-sm-7">
							<CustomDropDown :options="trackingRequest" :isDisabled="this.form.deleted_datetime" @item-selected="handleItemSelected" :initialValue="form.tracking_request_text" fieldName="tracking_request"></CustomDropDown>
							<ValidationErrorMessageList :errors="v$.form.tracking_request.$errors" />
						</div>
					</div>
					<div class="row pd-bottom" v-if="!this.isOtherType">
						<label for="text" class="col-sm-5 col-form-label right-bor">Status <span class="asterisk-red">*</span></label>
						<div class="col-sm-7">
							<CustomDropDown :options="trackingDocRole" :isDisabled="this.form.deleted_datetime"
								:initialValue="form.tracing_role_text" fieldName="tracing_role"
								@item-selected="handleItemSelected"></CustomDropDown>
								<ValidationErrorMessageList :errors="v$.form.tracing_role.$errors" />
						</div>
					</div>

					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Tag Provider</label>
						<div class="col-sm-7">
							<div class="selact_dropdown tag-provider-selct">
								<selectCheckBox :dataSource='provider_list' :labelName="tagProviderLabel" :isDisabled="this.form.deleted_datetime"
									@clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key"
									:isShowDoctorIcon="true" :isUnselectAll="isUnselectAll" divMainClass="" />
							</div>
						</div>
					</div>
					<div class="row pd-bottom align-items-start">
						<label for="text" class="col-sm-5 col-form-label right-bor">Notes</label>
						<div class="col-sm-7">
							<textarea class="form-control" rows="5" v-model="form.other_information" :readonly="this.form.deleted_datetime"></textarea>
						</div>
					</div>
				</div>
				<div class="inbox_split_page" v-if="this.isShowSplitPdfFrm">
					<h4 class="title_spl">SPLIT PAGES</h4>
					<p class="tagline_spl">the original document will be destroyed post-split, please make sure to assign
						all pages.</p>
					<div class="doc_detl_box" v-for="(item, index) in this.splitPdfEle" :key="index"
						:class="{ active: this.selectedDocId == index }" @click="changeSelectedDoc(index)">
						<div class="row align-items-center">
							<div class="col-sm-3">
								<label for="text" class="col-form-label">Document {{ item.id }}</label>
							</div>
							<div class="col-sm-9">
								<div class="custom-form">
									<div class="input_box">
										<input type="text" class="form-control" required v-model="item.value">
										<label class="form-label">Assign Page number (ex:1-3,7,9)</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center">
						<span class="plus_sign_btn" @click="addSplitPdf"> <i class="fa fa-plus fs-14"></i></span>
					</div>
				</div>
			</div>
			<div class="btn_part text-center">
				<button class="comman_brdr_btn big_btn mx36" @click="cancel"> Cancel </button>
				<button class="comman_btn big_btn mx36" v-if="!form.deleted_datetime" @click="save">Save
					<img src="images/loader.gif" v-if="loader" />
				</button>
				<button class="comman_btn big_btn mx36" v-else @click="restoreRecord(form.id)">Restore
					<img src="images/loader.gif" v-if="loader" />
				</button>
			</div>
		</div>
		<div class="col-lg-7">
			<div class="slide_deatil_box overflow-auto" ref="pdfContainer" v-if="documentPath != null" style="height: 100%;">
				<!-- <ejs-pdfviewer id="pdfViewer" ref="pdfviewer" :documentPath="documentPath" :resourceUrl="resourceUrl"
					@documentLoad="onDocumentLoad" :openFile='false' :toolbarSettings="toolbarSettings" :height="height"
					:enableTextSearch="true" :enableHyperlink="true" :enableMagnification="true" :enableToolbar="true"
					:enableNavigation="true" :enableThumbnail="false" :enableBookmark="true" :enableTextSelection="true"
					:enablePrint="true" :enableAnnotation="true" :enableFormFields="false" :enableFormDesigner="false">
				</ejs-pdfviewer> -->
				<div class="text-center" v-if="this.documentPath" style="width: 675px; height: 792px; margin: 0 auto;">
					<canvas id="pdfCanvas" ref="pdfCanvas" style="width: 100%; height: 100%;" @contextmenu="handleCanvasRightClick" ></canvas>
				</div>
				<div class="pdf-pagination-sticky" v-if="this.documentPath && isShowPagination">
					<div class="query-pagination text-center" v-if="totalPages > 1">
						<ul class="page-pagination p-0 mt-0">
							<li style="margin-right: 40px;">
								<img src="/images/left-arrow-pagen.svg" class="img-fluid" v-if="currentPage > 1"
									@click="goToPreviousPage">
							</li>
							<li v-if="totalPages > 1 || currentPage === 1">{{ currentPage }}</li>
							<li v-if="totalPages > 1">/</li>
							<li v-if="totalPages > 1 || currentPage === 1">{{ totalPages }}</li>
							<li style="margin-left: 40px;">
								<img src="/images/right-arrow-pagen.svg" class="img-fluid"
									v-if="currentPage < totalPages" @click="goToNextPage">
							</li>
						</ul>
					</div>
					<div class="query-pagination text-center" v-else>
						<ul class="page-pagination p-0 mt-0">
							<li>1</li>
							<li>/</li>
							<li>1</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';
// import {
// 	Toolbar, Magnification, Navigation, LinkAnnotation,
// 	BookmarkView, ThumbnailView, Print, TextSelection, TextSearch,
// 	Annotation
// } from '@syncfusion/ej2-vue-pdfviewer';
import selectCheckBox from "../base/selectCheckBox.vue";
import { PDFDocument } from 'pdf-lib';

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			form: this.$store.state.patient_document.validationRules.form,
		}
	},
	data() {
		return {
			docTypeList: [
					{ value: 'Consultation', title: 'Consultation' },
					{ value: 'Lab', title: 'Lab' },
					{ value: 'Imaging', title: 'Imaging' },
					{ value: 'Rx', title: 'Rx' },
					{ value: 'Others', title: 'Others' },
			],
			patientList: [],
			provider_list: [],
			provider_text: "Select Provider",
			loader: false,
			resourceUrl: "https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
			documentPath: null,
			toolbarSettings: {
				showTooltip: true,
				toolbarItems: [
					"UndoRedoTool",
					"PageNavigationTool",
					"MagnificationTool",
					"PanTool",
					"SelectionTool",
					"AnnotationEditTool",
					"FreeTextAnnotationOption",
					"InkAnnotationOption",
					"ShapeAnnotationOption",
					"StampAnnotation",
					"SignatureOption",
					"PrintOption",
					"DownloadOption"
				],
			},
			height: 2000,
			trackingDocRole: [],
			trackingDocTypeRole: {
				Consultation: [
					{ value: 'Created', title: 'Created' },
					{ value: 'Booked', title: 'Booked' },
					{ value: 'Rejected', title: 'Rejected' },
					{ value: 'Completed', title: 'Completed' }
				],
				Imaging: [
					{ value: 'Created', title: 'Created' },
					{ value: 'Booked', title: 'Booked' },
					{ value: 'Rejected', title: 'Rejected' },
					{ value: 'Completed', title: 'Completed' }
				],
				Lab: [
					{ value: 'Created', title: 'Created' },
					{ value: 'Received', title: 'Received' },
				],
				Rx: [
					{ value: 'Created', title: 'Created' },
					{ value: 'Received', title: 'Received' },
				]
			},
			isOtherType: false,
			trackingRequest: [],
			// Display flag for provider selection dropdown
			displayDiv: false,
			// Key to force re-rendering of the selectCheckBox component
			select_box_key: 0,
			isUnselectAll: false,
			tagProviderLabel: "Select Provider(s)",
			isShowInboxFrm: true,
			isShowSplitPdfFrm: false,
			splitPdfEle: [],
			selectedDocId: 0,

			pdfDoc: null,
			existingPdfBytes: null,
			totalPages: 0,
			currentPage: 1,
			isShowPagination: false,
		}
	},
	components: {
		CustomDropDown,
		ValidationErrorMessageList,
		selectCheckBox
	},
	methods: {
		patientSearch() {
			if (this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}
			if (this.patient_search.length == 0) {
				this.patientList = [];
				this.form.patient_id = null
			}
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				// this.patient_search = '';
				this.patientList = [];
			}
		},
		getProviderList() {
			axios.post("json_list/provider", { roleId: 2 })
				.then((response) => {
					this.provider_list = response.data.data.map((item) => {
						return { id: item.id, name: item.full_name, checked: false };
					});
				})
		},
		// getDocumentType() {
		// 	this.docTypeList = []
		// 	axios.post("json_autocomplete/document-type/list", {})
		// 		.then((response) => {
		// 			if (response.data.status === 200) {
		// 				const tempData = []
		// 				$.each(response.data.data, function (key, detail) {
		// 					if (!detail.deleted_datetime) {
		// 						tempData.push({
		// 							value: detail.id,
		// 							title: detail.type,
		// 						})
		// 					}
		// 				})
		// 				this.docTypeList = tempData
		// 			} else {
		// 				this.$filters.moshaToast(response.data.message, "error")
		// 			}
		// 		})
		// 		.catch(error => {
		// 			this.$filters.moshaToast(error.message, "error")
		// 		});
		// },
		edit() {
			if (this.$route.name === "InboxEdit") {
				axios.post("patient/document/retrieve", { 'id': this.$route.params.id })
					.then((response) => {
						if (response.data.status === 200) {
							let detail = response.data.data
							this.patient_search = detail.patient_name
							this.$store.state.patient_document.form = detail;
							this.documentPath = this.documentUrl(detail.file_path);
							if (this.documentPath) {
								this.modifyPdf();
							}
							this.handleItemSelected({ fieldName: 'report_class_id', item: { value: detail.report_class_id, title: detail.report_class_id } });
							this.provider_text = "Select Provider"
							if (detail.recipientName) {
								this.provider_text = detail.recipientName
							}
							this.splitPdfEle = [{
								'value': detail.split_pages, 'id': 1
							}]
						} else {
							this.$filters.moshaToast(response.data.message, "error")
							this.$store.state.loader = false;
						}
					}).catch(error => {
						this.$filters.moshaToast(error.message, "error")
						this.$store.state.loader = false;
					});
			}
		},
		async modifyPdf() {
			try {
				if (!this.documentPath) {
					throw new Error('Document path is not defined.');
				}

				this.$store.state.patient_document.form.annotatedPdfBytes = await fetch(this.documentPath).then(res => res.arrayBuffer());
				this.existingPdfBytes = this.$store.state.patient_document.form.annotatedPdfBytes;
				this.pdfDoc = await PDFDocument.load(this.existingPdfBytes);
				await this.pdfDoc.save();

				this.totalPages = this.pdfDoc.getPageCount();
				if(this.totalPages > 0)
				{
					this.isShowPagination = true;
				}
				this.renderPage(this.currentPage);
			} catch (error) {
				console.error('Error modifying PDF:', error);
			}
		},
		async renderPage(pageNumber) {
			try {
				await this.$nextTick();

				const canvas = this.$refs.pdfCanvas;
				if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
					// await this.retryGetCanvas();
					throw new Error('Canvas element is not found or is not a valid HTMLCanvasElement.');
				}

				const context = canvas.getContext('2d');
				if (!context) {
					throw new Error('Unable to get 2D context of canvas.');
				}

				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;
				const page = await pdf.getPage(pageNumber);

				const viewport = page.getViewport({ scale: 1 });
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				await page.render(renderContext).promise;
				this.$store.state.loader = false;

			} catch (error) {
				console.error('Error rendering page:', error);
			}
		},
		goToNextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage++;
				this.renderPage(this.currentPage, 'next');
			}
		},
		goToPreviousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.renderPage(this.currentPage, 'previous');
			}
		},
		selectPatient(id, patient_name) {
			this.$store.state.patient_document.form.patient_id = id
			this.patient_search = patient_name
			this.handleItemSelected({ fieldName: 'report_class_id', item: { value: this.form.report_class_id, title: this.form.report_class_id } });
		},
		save() {
			if (this.isShowSplitPdfFrm) {
				this.saveSplitPdf()
			} else {
				let formName = 'form'
				this.v$[formName].$validate();
				const $this = this
				if (!this.v$[formName].$error) {
					this.loader = true
					this.form.recipientName = this.provider_text
					if (this.form.tracing_role == 'Select'){
						this.form.tracing_role = 'Created'
					}
					const form = this.form;
					let url
					if (this.$route.name === "InboxEdit") {
						url = "patient/document/update";
					} else {
						url = "patient/document/save";
					}

					axios.post(url, form)
						.then((response) => {
							this.loader = true
							if (response.status == 200) {
								this.loader = false
								setTimeout(function () {
									$this.$filters.moshaToast(response.data.message, "success")
									$this.$router.push({ name: 'Inbox', params: {} });

								}, 400);
							} else {
								this.loader = false
								this.$filters.moshaToast(response.data.message, "error")
							}
						}).catch(error => {
							this.$filters.moshaToast(error.message, "error")
							this.loader = false
						});

				} else {
					console.log(Object.entries(this.v$.form))
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
			}
		},
		deleteRecord(id) {
			this.$swal({
				title: 'Are you sure to DELETE this Inbox?',
				text: '',
				icon: '',
				showCancelButton: true,
				confirmButtonColor: '#DD6B55',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				closeOnConfirm: false,
				closeOnCancel: false,
				reverseButtons: true, // This will swap the Confirm and Cancel buttons
				customClass: {
					container: 'my-swal-container',
					popup: 'my-swal-popup delete-popup',
					header: 'my-swal-header',
					title: 'my-swal-title',
					closeButton: 'my-swal-close-button',
					icon: 'my-swal-icon',
					image: 'my-swal-image',
					content: 'my-swal-content',
					input: 'my-swal-input',
					actions: 'my-swal-actions',
					confirmButton: 'my-swal-confirm-button',
					cancelButton: 'my-swal-cancel-button',
					footer: 'my-swal-footer'
				}
			}).then((result) => {
				if (result.isConfirmed) {
					let url = "patient/document/delete";
					axios.post(url, { 'ids': [id] })
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.$router.push({ name: 'Inbox', params: {} });
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						})
					// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
				} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
				}
			});
		},
		cancel() {
			if (this.form.deleted_datetime) {
				this.$router.push({ name: 'InboxDeletedList', params: {} });
			} else {
				this.$router.push({ name: 'Inbox', params: {} });
			}
		},
		handleItemSelected(values) {
			if (values.fieldName == 'report_class_id') {

				this.form.tracking_request_text = "Select Link with Tracing Requests"
				this.form.tracking_request = null

				this.form.report_class_id = values.item.value
				this.form.lab_id = null
				this.form.imaging_id = null
				this.form.consultation_id = null
				this.form.prescription_id = null
				this.form.report_sub_class_name = null
				this.form.tracing_role_text = 'Select Status'
				this.form.tracing_role = null
				
				if (values.item.title == 'Others' || values.item.title == 'Rx') {
					this.isOtherType = true
				} else {
					this.isOtherType = false
					if (values.item.title == 'Consultation') {
						this.getconsultationList();
					} else if (values.item.title == 'Imaging') {
						this.getImagingList();
					} else if (values.item.title == 'Lab') {
						this.getLabList();
					} else if (values.item.title == 'Rx') {
						this.getPrescriptionList();
					}
				}
				
				this.trackingDocRole = []
				if (this.trackingDocTypeRole[values.item.title]) {
					this.trackingDocRole = this.trackingDocTypeRole[values.item.title]
					this.form.tracing_role = this.trackingDocRole.title
					this.form.tracing_role_text = this.trackingDocRole.title

				}

			}
			if (values.fieldName == 'provider_ids') {
				this.provider_text = values.item.title
				this.form.provider_ids = values.item.id
			}
			if (values.fieldName == 'tracing_role') {
				this.form.tracing_role = values.item.value
				this.form.tracing_role_text = values.item.value
			}

			if (values.fieldName == 'tracking_request') {
				this.form.lab_id = null
				this.form.imaging_id = null
				this.form.consultation_id = null
				this.form.prescription_id = null

				if (this.form.report_class_id == 'Consultation') {
					this.form.consultation_id = values.item.id
				} else if (this.form.report_class_id == 'Imaging') {
					this.form.imaging_id = values.item.id
				} else if (this.form.report_class_id == 'Lab') {
					this.form.lab_id = values.item.id
				} else if (this.form.report_class_id == 'Rx') {
					this.form.prescription_id = values.item.id
				}

				this.form.tracking_request = values.item.title
				this.form.tracking_request_text = values.item.title
			}
		},
		documentUrl(documentPath) {
			// Assuming your documents are stored in the 'public' directory
			return `${process.env.VUE_APP_STORAGE_URL}/document/view/${documentPath}`;
		},
		getconsultationList() {
			this.trackingRequest = []
			let patientId = this.$route.params.patient_id
			if (this.$route.name === "InboxEdit") {
				patientId = this.form.patient_id
			}

			let status = null
			if (this.form.tracing_role && this.form.tracing_role != 'Select') {
				status = this.form.tracing_role
			}

			axios.post("patient/consultations/list", { 'patient_id': patientId, 'status':status })
				.then((response) => {
					this.trackingRequest = response.data.data.map((item) => {
						return { id: item.id, title: item.referral_date + " | " + item.specialist_name, checked: false };
					});

					this.form.tracking_request_text = `${this.trackingRequest.length} Existing Requests`
				})

		},
		getImagingList() {
			this.trackingRequest = []

			let patientId = this.$route.params.patient_id
			if (this.$route.name === "InboxEdit") {
				patientId = this.form.patient_id
			}

			axios.post("patient/imaging/list", { 'patient_id': patientId, 'status':this.form.tracing_role })
				.then((response) => {
					this.trackingRequest = response.data.data.map((item) => {
						return { id: item.id, title: item.referral_date + " | " + item.service, checked: false };
					});

					this.form.tracking_request_text = `${this.trackingRequest.length} Existing Requests`
				})
			
		},
		getLabList() {
			this.trackingRequest = []

			let patientId = this.$route.params.patient_id
			if (this.$route.name === "InboxEdit") {
				patientId = this.form.patient_id
			}

			axios.post("patient/lab/list", { 'patient_id': patientId, 'status':this.form.tracing_role })
				.then((response) => {
					this.trackingRequest = response.data.data.map((item) => {
						return { id: item.id, title: item.ordered_datetime + " | " + item.lab_name, checked: false };
					});
					this.form.tracking_request_text = `${this.trackingRequest.length} Existing Requests`

				})
		},
		getPrescriptionList() {
			this.trackingRequest = []

			let patientId = this.$route.params.patient_id
			if (this.$route.name === "InboxEdit") {
				patientId = this.form.patient_id
			}

			axios.post("patient/prescriptions/list", { 'patient_id': patientId, 'status':this.form.tracing_role })
				.then((response) => {
					this.trackingRequest = response.data.data.map((item) => {
						return { id: item.id, title: item.written_date + " | " + item.drug_name, checked: false };
					});
					this.form.tracking_request_text = `${this.trackingRequest.length} Existing Requests`

				})
		},
		selected_provider(result) {
			this.form.provider_ids = result.filter(item => item.checked).map(item => item.id);
			if (this.form.provider_ids.length == 1) {
				this.tagProviderLabel = `${this.form.provider_ids.length} Provider Selected`;
			} else if (this.form.provider_ids.length > 1) {
				this.tagProviderLabel = `${this.form.provider_ids.length} Providers Selected`;
			} else {
				this.tagProviderLabel = "Select Provider(s)";
			}
		},
		splitpdf() {
			this.isShowInboxFrm = false
			this.isShowSplitPdfFrm = true
		},
		addSplitPdf() {
			const lastItem = this.splitPdfEle[this.splitPdfEle.length - 1]; // Accessing the last item in the array
			this.splitPdfEle.push({ 'value': '', 'id': lastItem.id + 1 })
			this.selectedDocId = lastItem.id
		},
		saveSplitPdf() {
			let $this = this
			let id = this.form.id
			let url = 'patient/document/split'
			let form = {
				id: id,
				pages_number: this.splitPdfEle
			}
			this.loader = true
			axios.post(url, form)
				.then((response) => {
					if (response.status == 200) {
						this.loader = false
						setTimeout(function () {
							$this.$filters.moshaToast(response.data.message, "success")
							$this.$router.push({ name: 'Inbox', params: {} });

						}, 400);
					} else {
						this.loader = false
						this.$filters.moshaToast(response.data.message, "error")
					}
				}).catch(error => {
					this.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		changeSelectedDoc(index) {
			this.selectedDocId = index
		},
		restoreRecord(id){
				this.$swal({
					title: 'Are you sure to RESTORE this record?',
					text: '',
					icon: '',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Restore',
					cancelButtonText: 'Cancel',
					closeOnConfirm: false,
					closeOnCancel: false,
                    reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/document/restore";
						axios.post(url, {'ids':[id]})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
								this.$router.push({ name: 'Inbox', params: {} });
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
			}
	},
	mounted() {
		this.$store.state.loader = true;
		const pdfScript = document.createElement('script');
		pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js';
		// Letest
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js';
		// client
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.min.js';

		pdfScript.onload = () => {
			const workerScript = document.createElement('script');
			workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
			// Letest
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';
			// client
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.17.1/pdf-lib.min.js';

			workerScript.onload = () => {
				// Initialize your PDF logic here after both scripts are loaded
				this.edit();  // Call this.edit() after both scripts are fully loaded
			};

			document.head.appendChild(workerScript);
		};

		document.head.appendChild(pdfScript);

		this.getProviderList()
		document.addEventListener('click', this.handleClickOutside);
		// this.getDocumentType()
		if (this.$route.name === "InboxEdit") {
			this.edit();
		} else {
			this.$store.state.loader = false;
		}
	},
	computed: {
		form() {
			return this.$store.state.patient_document.form;
		},

	},
	// provide: {
	// 	PdfViewer: [Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation]
	// }
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css";
</style>